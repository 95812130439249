import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import "../styles/main.css"
import { Helmet } from "react-helmet"



const IndexPage = () => {
    return (

    <main className="rad">
    <Helmet>  
        <title>Martin Zerr – Fahrradfotografie</title>
    </Helmet> 
        <a className="back" href="/">zurück</a>
        <h1>Fahrradfotografie</h1>

        <div className="rad_wrapper">
             <Zoom><StaticImage src="../images/seite.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
             <Zoom><StaticImage src="../images/kette.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
             <Zoom><StaticImage src="../images/oben.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
             <Zoom><StaticImage src="../images/reifen.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
             <Zoom><StaticImage src="../images/hinten_v2.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        </div>

        <a className="kontakt" href="/kontakt">Kontakt</a>


    </main>

)
}

export default IndexPage